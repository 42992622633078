<template>
  <CmsLayout>
    <main>
      <div v-if="!isLoaded" class="loader">
        <LoaderSpinner />
        <div v-html="getTranslation('qr_payment_wait')" />
      </div>
      <StatusMessage
        v-else
        :status="!!isSubscribed"
        :title="resultTitle"
        :text="resultText"
        :button-text="buttonText"
        @clickButton="onClick"
      />
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from '../mixins';
import {
  HTTP_CODES,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUSES_REASONS,
  PENDING_TIMEOUT,
} from 'src/constants';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import StatusMessage from 'src/components/StatusMessage.vue';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import {
  checkPaymentStatusTrials,
  isPaymentSuccessful,
  getPaymentStatus,
} from 'src/utils/quick-subscribe';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { wlDetector } from 'src/utils';
import logger from 'src/utils/logger';

const log = logger('acquiring-status-page');

@Component({
  components: {
    StatusMessage,
    CmsLayout,
    LoaderSpinner,
  },
})
export default class AcquiringStatusPage extends SequoiaComponent {
  isLoaded = false;
  isSubscribed?: boolean;
  error = false;

  @Watch('isLoaded')
  onIsLoadedChange(val: boolean) {
    actions.common[val ? 'showFooter' : 'hideFooter'](this.$store);
  }

  get serverError() {
    return this.$store.QS.serverError;
  }

  get errorMessage() {
    return this.serverError.message;
  }

  get errorCode() {
    return this.serverError.code;
  }

  get resultTitle() {
    let translationKey = '';

    if (this.isSubscribed) {
      translationKey = 'subscribed';
    } else if (this.errorCode) {
      translationKey =
        this.errorCode === HTTP_CODES.PRECONDITION_FAILED ? 'payment_failed_412' : 'payment_failed';
    } else {
      translationKey = 'acquiring_status_forbidden';
    }

    return this.getTranslation(translationKey);
  }

  get resultText() {
    if (this.errorMessage) {
      return this.errorMessage;
    } else if (!this.error) {
      return this.getTranslation(this.isSubscribed ? 'subscribed_notes' : 'payment_failed_notes');
    }

    if (this.error) {
      return this.getTranslation('acquiring_status_forbidden_details');
    }
  }

  get buttonText() {
    return this.$route.query.source_url
      ? this.isSubscribed
        ? this.getTranslation('go_to_source')
        : this.getTranslation('try_again')
      : this.getTranslation('go_to_main');
  }

  async mounted() {
    this.isSubscribed = this.$route.query.subscribed === 'true';
    const subscriptionId = (this.$route.query.subscription_id || '').toString();

    if (wlDetector().isUztel && this.isSubscribed) {
      try {
        await actions.payment.loadPaymentSubscriptionsV2(this.$store);
      } catch (err) {
        log.error('onLoadPaymentSubscriptions', err);
      }
    }

    if (subscriptionId) {
      try {
        const { status, cancellation_details: cancellationDetails } = await getPaymentStatus(
          subscriptionId
        );

        if (status === SUBSCRIPTION_STATUSES.PENDING) {
          setTimeout(async () => {
            const { status } = await getPaymentStatus(subscriptionId);
            if (status === SUBSCRIPTION_STATUSES.PENDING) {
              this.isSubscribed = false;
            }
          }, PENDING_TIMEOUT);
        } else if (
          status === SUBSCRIPTION_STATUSES.CANCELED &&
          cancellationDetails.reason === SUBSCRIPTION_STATUSES_REASONS.CANCELED_BY_MERCHANT
        ) {
          // in case of trials there will be payment of about 1 rouble to check whether credit card is valid and has money
          // and straight after that there will be refund of this 1 rouble so the payment will be canceled
          // so in case if status is canceled - we should check whether subscription is active or not
          this.isSubscribed = await checkPaymentStatusTrials(subscriptionId);
        } else {
          this.isSubscribed = isPaymentSuccessful(status);
        }
        this.isLoaded = true;
      } catch (err) {
        this.error = true;
        this.isSubscribed = false;
        this.isLoaded = true;
      } finally {
        this.gaEvent({
          category: 'acquiring',
          action: this.isSubscribed ? 'Оплата прошла успешно' : 'Ошибка оплаты',
          qs_qr: this.$route.query.token ? 'qr' : 'qs',
          offer_id: subscriptionId,
        });
      }
    } else {
      this.isLoaded = true;
    }
  }

  destroyed() {
    this.$store.QS.serverError.message = '';
    this.$store.QS.serverError.code = '';
    actions.common.hideFooter(this.$store);
  }

  async onClick() {
    const url = this.$route.query.source_url;
    await this.$router.push(url ? `${url}` : '/');
  }
}
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
}
</style>
